'use client'

import Script from 'next/script'
import { useUser } from '@clerk/nextjs'

import { publicConfig } from '~/config'

const IntercomScript = ({
	appId,
	hash,
}: {
	appId: string
	hash: string
}) => {
	const {
		user,
		isLoaded,
		isSignedIn,
	} = useUser()
	const organizations = user?.organizationMemberships.map((o) => {
		return {
			id: o.organization.id,
			name: o.organization.name,
			created_at: o.organization.createdAt,
			plan: publicConfig.intercom.plan,
		}
	})

	if (!isLoaded || !isSignedIn || !organizations) {
		return null
	}

	const companies = JSON.stringify(organizations)
		.replaceAll(/["'\\`]/g, '\\$&')
		// eslint-disable-next-line no-control-regex
		.replaceAll('\0', '\\0')

	return (
		<Script
			id="intercom"
			dangerouslySetInnerHTML={{
				__html: `
					window.intercomSettings = {
							app_id: "${appId}",
							name: "${user.fullName}",
							email: "${user.emailAddresses[0].emailAddress}",
							user_hash: "${hash}",
							${user.createdAt ? `created_at: "${user.createdAt.toString()}",` : ''}
							avatar: {
								type: "avatar",
								image_url: "${user.imageUrl}",
							},
							companies: JSON.parse(\`${companies}\`),
						};
					if (!window.matchMedia('(max-width: 768px)').matches) {
						(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
					}
				`,
			}}
		/>
	)
}

export default IntercomScript
